import React from 'react';
import './Footer.css';
import '../../styles/inputStyles.css';
import PromptWithActionLink from '../common/PromptWithActionLink';

const Footer = ({ goToContactPage, goToTerms, goToConfirmation }) => {
    return (
        <footer className="footer-container text">
            <div className="promptWithActionLink-container">
                <button
                    onClick={() => {
                        console.log("Contacts pressed");
                        goToContactPage();
                    }}
                    className="footer-container-button">
                    Contact us
                </button>
            </div>

            <div className="promptWithActionLink-container">
                <button
                    onClick={() => {
                        console.log("Terms of Use and Cancellation Policy pressed");
                        goToTerms();
                    }}
                    className="footer-container-button">
                    Terms of Use and Cancellation Policy
                </button>
            </div>

            {/*<div className="promptWithActionLink-container">*/}
            {/*    <button*/}
            {/*        onClick={() => {*/}
            {/*            console.log("Demo payment");*/}
            {/*            goToConfirmation();*/}
            {/*        }}*/}
            {/*        className="footer-container-button">*/}
            {/*        Demo payment1*/}
            {/*    </button>*/}
            {/*</div>*/}
        </footer>
    );
}

export default Footer;
